import Typography from "@material-ui/core/Typography/Typography";
import * as React from "react";

import styled from "~/components/core/styled";

interface Props {
  title: string;
  subtitle?: string;
}

const SectionTitle: React.FC<Props> = ({
  title,
  subtitle
}: Props): JSX.Element => {
  return (
    <Wrapper>
      <Title variant="h4">{title}</Title>
      <Subtitle variant="subtitle1">{subtitle}</Subtitle>
    </Wrapper>
  );
};

export default SectionTitle;

const Wrapper = styled.div`
  margin-left: 0;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    margin-left: 10px;
  }
`;

const Title = styled(Typography)`
  font-weight: 900;
  margin: 25px 0 2px 0;
`;

const Subtitle = styled(Typography)`
  margin-bottom: 15px;
`;
