import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

import styled from "~/components/core/styled";
import VoidPlaceholder from "~/components/core/VoidPlaceholder";
import { REQUEST_STATE } from "~/constants/apiInteraction";

import ErrorPlaceholder from "../ErrorPlaceholder";

interface Props {
  hasData?: boolean;
  requestState?: REQUEST_STATE;
}

export const WidgetContentNew: React.FC<{
  hasData: boolean | number | undefined | null;
  loading: boolean;
  error?: Error;
}> = ({ children, loading, hasData, error }) => {
  if (loading) {
    return <WidgetCardLoadingPlaceholder />;
  }
  if (error) {
    return (
      <WidgetCardLoadingPlaceholder animation={false}>
        <ErrorWrapper>{error.toString()}</ErrorWrapper>
      </WidgetCardLoadingPlaceholder>
    );
  }
  if (!hasData) {
    return <VoidPlaceholder />;
  }
  return <>{children}</>;
};

const WidgetContent: React.FC<Props> = ({
  children,
  requestState,
  hasData
}) => {
  switch (requestState) {
    case REQUEST_STATE.init:
    case REQUEST_STATE.loading:
      return <WidgetCardLoadingPlaceholder />;
    case REQUEST_STATE.error:
      return <ErrorPlaceholder />;
    default:
      if (hasData) {
        return <>{children}</>;
      } else {
        return <VoidPlaceholder />;
      }
  }
};

export default WidgetContent;

export const WidgetCardLoadingPlaceholder = styled(Skeleton)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  transform: unset;
`;

const ErrorWrapper = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
`;
