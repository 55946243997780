import { useRouter } from "next/router";
import React, { MutableRefObject } from "react";

import { theme } from "~/theme/theme";
import { isNotNull } from "~/utils/common";
import { useIfMounted } from "~/utils/useIfMounted";

const ANCHOR_REG = /[^#]*$/;
const INITIALIZE_DELAY = 1000;
const OBSERVER_MARGIN = "0px 0px -90% 0px";

interface Props {
  refs: {
    id: string;
    ref: MutableRefObject<HTMLDivElement | null>;
    forceOffset?: number;
  }[];
}

const AnchorIntersectionObserver: React.FC<Props> = ({ refs }: Props): null => {
  const ifMounted = useIfMounted();
  const [target, setTarget] = React.useState("");
  const [initializedTarget, setInitializedTarget] = React.useState(false);
  const [initializedRefs, setInitializedRefs] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    const { asPath } = router;

    if (!initializedTarget) {
      const target: string | undefined = asPath.match(ANCHOR_REG)?.[0];
      const targetRef = refs.find(({ id }) => id === target);

      const timeout = setTimeout(() => {
        let offsetTop = 0;

        if (targetRef && isNotNull(targetRef?.forceOffset)) {
          offsetTop = targetRef.forceOffset ?? 0;
        } else if (targetRef?.ref?.current?.offsetTop) {
          offsetTop = targetRef?.ref?.current?.offsetTop;
        }

        window.scrollTo({
          top: offsetTop
            ? offsetTop - theme.layouts.account.filterToolbar.height
            : offsetTop
        });
        ifMounted(() => setInitializedTarget(true));
      }, INITIALIZE_DELAY);

      return (): void => clearTimeout(timeout);
    }
  }, [refs, router, initializedTarget, ifMounted]);

  React.useEffect(() => {
    if (initializedTarget) {
      window.history.replaceState("", "", `#${target}`);
    }
  }, [target, initializedTarget]);

  const handleObserve = React.useCallback(
    (entries: IntersectionObserverEntry[]): void => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          setTarget(entry.target.id);
        }
      });
    },
    [setTarget]
  );

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(handleObserve, {
        rootMargin: OBSERVER_MARGIN
      }),
    [handleObserve]
  );

  React.useEffect(() => {
    if (!initializedRefs) {
      refs.map(({ ref }) => {
        ref?.current && observer.observe(ref?.current);
      });

      setInitializedRefs(true);
    }

    return (): void => observer.disconnect();
  }, [refs, observer, initializedRefs]);

  return null;
};

export default AnchorIntersectionObserver;
