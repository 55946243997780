import Card from "@material-ui/core/Card";
import React from "react";

import styled from "~/components/core/styled";
import WidgetCardContent from "~/components/core/WidgetCard/WidgetCardContent";
import { REQUEST_STATE } from "~/constants/apiInteraction";
import { isString } from "~/utils/common";

import ClarificationIcon from "../ClarificationIcon";

export interface WidgetCardProps {
  id?: string;
  caption?: string;
  headerEndAdornment?: React.ReactNode;
  hasData?: boolean;
  withPadding?: boolean;
  clarification?: string;
  contentHeight?: number | string;
  requestState?: REQUEST_STATE;
  className?: string;
  wrapContent?: boolean;
  children?: React.ReactNode;
}

const WITH_PADDING_POSITIVE = "withpadding";

const WidgetCard = React.forwardRef<HTMLElement, WidgetCardProps>(
  (
    {
      id,
      caption,
      clarification,
      headerEndAdornment,
      contentHeight,
      children,
      requestState,
      className,
      withPadding = true,
      hasData = false,
      wrapContent = true
    },
    ref
  ) => (
    <Wrapper id={id || ""} ref={ref} elevation={3} className={className}>
      {caption && (
        <Header>
          <Caption>{caption}</Caption>
          <Filler />
          {headerEndAdornment}
          <ClarificationIcon clarification={clarification} />
        </Header>
      )}
      <CardContent
        height={contentHeight}
        withpadding={withPadding ? WITH_PADDING_POSITIVE : ""}
      >
        {wrapContent ? (
          <WidgetCardContent requestState={requestState} hasData={hasData}>
            {children}
          </WidgetCardContent>
        ) : (
          children
        )}
      </CardContent>
    </Wrapper>
  )
);

export default WidgetCard;

export type LazyWidgetCardProps = {
  caption: string;
  clarification: string;
  contentHeight: number;
  className?: string;
  visible: boolean;
  children: React.ReactChild;
};

const Wrapper = styled(Card)`
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    margin: 10px;
  }
`;

const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[400]};
  display: flex;
  height: 75px;
  justify-content: space-between;
  padding: 0 5px;
`;

const Caption = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 24px;
  margin-left: 12px;
  max-width: 80%;
  overflow: hidden;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    font-size: 12px;
  }
`;

export const CardContent = styled.div<{
  height?: number | string;
  withpadding?: string;
}>`
  height: ${({ height }): string => {
    if (!height) return "auto";

    if (isString(height)) {
      return height;
    }

    return `${height}px`;
  }};
  overflow-y: auto;
  padding: ${({ withpadding }): number =>
    withpadding === WITH_PADDING_POSITIVE ? 10 : 0}px;
`;

const Filler = styled.div`
  margin-left: auto;
`;
